<template>
  <b-card-actions title="管理员设置" @refresh="refreshStop()" ref="refreshCard">
    <!-- <b-row class="filter">
      <b-col cols="12" md="4" class="mb-md-0 mb-2">
        <label>姓名:</label>
        <input type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon1">
      </b-col>
      <b-col cols="12" md="4" class="mb-md-0 mb-2">
        <label>角色</label>
        <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="roleFilter" :options="roleOptions"
          @change="getRole(roleFilter)" class="w-100" :reduce="val => val.value"
          @input="(val) => $emit('update:roleFilter', val)" />
      </b-col>
      <b-col cols="12" md="4" class="mb-md-0 mb-2">
        <label>状态</label>
        <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="planFilter" :options="planOptions"
          @change="getStause(planFilter)" class="w-100" :reduce="val => val.value"
          @input="(val) => $emit('update:planFilter', val)" />
      </b-col>
    </b-row> -->
    <b-row>
      <b-col lg="9" class="mb-1">
        <b-button-group>
          <b-button variant="primary" class="btn-icon" v-b-toggle.sidebar-1 @click="clearfrom()"><feather-icon
              icon="PlusCircleIcon" /></b-button>
          <b-button variant="primary" class="btn-icon" @click="selectAllRows()"><feather-icon
              icon="CheckCircleIcon" />&nbsp;<span class="align-middle">{{ select_mode }}</span></b-button>
          <b-button variant="primary" class="btn-icon" @click="deleteAllRows()"><feather-icon
              icon="Trash2Icon" />&nbsp;<span class="align-middle">删除</span></b-button>
        </b-button-group>
      </b-col>
      <b-col lg="3" class="mb-1">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text><feather-icon icon="SearchIcon" /></b-input-group-prepend>
          <b-form-input placeholder="用户姓名" v-model="search_name" />
          <b-input-group-append><b-button variant="outline-primary"
              @click="get_data()">检索</b-button></b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-table ref="selectableTable" @row-selected="onRowSelected" selectable select-mode="multi" class="tabledata"
          striped hover responsive="sm" :items="items" :fields="fields" sticky-header no-border-collapse>
          <!-- 名字 -->
          <template #cell(name)="data" >
            <b-media vertical-align="center">
              <template #aside >
                <span @click="get_details(data.item.id)" v-b-toggle.sidebar-2>
                <b-avatar size="40" :src="data.item.avatar" :text="avatarText(data.item.name)" />
                </span>
              </template>
              <span> {{ data.item.name }}</span><br>
              <span> {{ data.item.account }}</span>
            </b-media>
          </template>
          <template #cell(role_id)="data">
            <div class="text-nowrap">
              <feather-icon :icon="resolveUserRoleIcon(data.item.role_id)" size="18" class="mr-50"
                :class="`text-${resolveUserRoleVariant(data.item.role_id)}`" />
              <span class="align-text-top text-capitalize">{{ data.item.role_id }}</span>
            </div>
          </template>
          <!-- 状态 -->
          <template #cell(status)="data">
            <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.status)}`" class="text-capitalize">
              <span v-if="data.item.status == 1">正常</span>
              <span v-else>冻结</span>
            </b-badge>
          </template>
          <!-- 设置 -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item @click="get_details(data.item.id)" v-b-toggle.sidebar-2>
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">详情</span>
              </b-dropdown-item>

              <b-dropdown-item @click="get_edit(data.item.id)" v-b-toggle.sidebar-1>
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">编辑</span>
              </b-dropdown-item>

              <b-dropdown-item @click="get_del(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">删除</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number last-number
          prev-class="prev-item" next-class="next-item" class="mb-0">
          <template #prev-text> <feather-icon icon="ChevronLeftIcon" size="18" /></template>
          <template #next-text> <feather-icon icon="ChevronRightIcon" size="18" /></template>
        </b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-sidebar id="sidebar-1" shadow bg-variant="white" title="用户编辑" right backdrop width="auto">
          <addition :editData="editData" @get_data="get_data" ref="mod"></addition>
        </b-sidebar>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-sidebar id="sidebar-2" shadow bg-variant="white" title="用户详情" right backdrop width="auto">
          <Details :detailstData="detailstData" @get_data="get_data" ref="detailst"></Details>
        </b-sidebar>
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import {
  BDropdown, BDropdownItem,
  BLink,
  BBadge, BAvatar, BMedia, BCardHeader, BCardBody, BCard, BCardText, BPagination, BButton,
  BPopover, BTable, BFormGroup, BFormSelect, BFormInput, BInputGroupAppend, BButtonGroup, BRow, BCol,
  BInputGroup, BInputGroupPrepend, BSidebar, VBToggle
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import addition from '@/views/system/addition/add.vue'
import Details from '@/views/system/addition/details.vue'

import { avatarText } from '@core/utils/filter'
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BLink,
    BBadge,
    BMedia,
    addition,
    BCardHeader,
    BCardBody,
    BTable,
    BCard,
    BCardText,
    BCardActions,
    BButton,
    BPopover,
    BPagination,
    BFormGroup,
    BFormSelect,
    BInputGroupAppend,
    BButtonGroup,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BSidebar,
    VBToggle,
    vSelect,
    ToastificationContent,
    BAvatar,
    avatarText,
    Details
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      // Filter
      avatarText,
      fields: [
        { key: 'id', label: 'ID', },
        { key: 'name', label: '姓名', }, 
        { key: 'role_id', label: '角色', },
        { key: 'phone', label: '电话', },
        { key: 'email', label: '邮箱', },
        { key: 'status', label: '状态', },
        { key: 'actions', label: '设置', },
      ],
      items: [],
      table_height: 400,
      currentPage: 1,
      perPage: 10,
      rows: 0,
      search_name: "",
      selected: [],
      select_all: 0,
      select_mode: "全选",
      roleFilter: '',
      planFilter: '',
      statusFilter: '',
      roleOptions: [],
      planOptions: [{ label: '冻结', value: '0' },
      { label: '正常', value: '1' },],
      editData: {
        act: '',
        id: "",
        avatar: '',
        name: '',
        email: '',
        phone: '',
        account: '',
        password: '',
        status: '',
        role_id: ''
      },
      detailstData: {
        act: '',
        id: "",
        avatar: '',
        name: '',
        email: '',
        phone: '',
        account: '',
        password: '',
        status: '',
        role_id: ''
      }
    }
  },
  created() {
    this.get_data()
  },
  watch: {
    currentPage: function (oldV, newV) {
      this.get_data()
    },
  },
  methods: {
    clearfrom(){
      this.$refs.mod.clearfrom()
    },
    getRole(val) {
      console.log(val, '11');
      this.planFilter = val
    },
    getStause(val) {
      console.log(val, '22');
      this.planFilter = val
    },

    onRowSelected(items) {
      this.selected = items
      if(items.length>0){
        this.showToast('success', '选中了' + (items.length) + '条数据!')
      }
    },
    selectAllRows() {
      if (this.select_all == 1) {
        this.clearSelected()
        return
      }
      this.$refs.selectableTable.selectAllRows()
      this.select_all = 1
      this.select_mode = "取消全选"
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
      this.select_all = 0
      this.select_mode = "全选"
    },
    refreshStop() {
      this.currentPage = 1
      this.search_name = ""
      this.get_data()
    },

    get_data() {
      this.$nextTick(() => {
        this.$refs["refreshCard"].showLoading = true
      })
      let dataObj = {
        act: "lst",
        pge: this.currentPage,
        cnt: this.perPage,
        name: this.search_name,
      }
      this.$http.post('svr/admin_users.php', dataObj)
        .then(res => {
          this.items = res.data.lst
          this.rows = res.data.cnt_all
          setTimeout(() => {
            this.$refs["refreshCard"].showLoading = false
          }, 300)
        })
    },
    del_data(ids) {
      let dataObj = {
        act: "del",
        id: ids,
      }
      this.$http.post('svr/admin_users.php', dataObj)
        .then(res => {
          this.showToast('success', '删除成功')
          this.get_data()
        })
    },
    deleteAllRows(id) {
      let ids = this.set_delids(id)
      if (!this.selected.length > 0&&!id>0) {
        this.showToast('danger', '请先选择您要删除的数据!')
        return
      }
      this.$swal({
        title: '确定删除数据吗?',
        text: "此操作将不可逆,请谨慎操作",
        //icon: 'warning',
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '我确定!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.del_data(ids)
        }
      })
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '系统提示',
          icon: 'BellIcon',
          text: text,
          variant,
        },
      })
    },
    set_delids(id) {
      let val = this.selected
      let ids = []
      val.forEach(item => {
        ids.push(item.id)
      })
      ids = ids.join();
      if(id){
        ids = id;
      }
      return ids;
    },
    get_details(e) {
      let getdata = {
        act: 'get',
        id: e
      }
      this.$http.post('svr/admin_users.php', getdata).then(res => {
        console.log(res, 'res');
        if (res.code == 20000) {
          this.detailstData = res.data
          console.log(this.detailstData, '详情数据');
        } else {

        }
      })
    },
    get_del(val) {
      this.deleteAllRows(val)
    },
    get_edit(e) {
      let getdata = {
        act: 'get',
        id: e
      }
      this.$http.post('svr/admin_users.php', getdata).then(res => {
        console.log(res, 'res');
        if (res.code == 20000) {
          this.editData = res.data
          console.log(this.editData, '传递数据');
        } else {

        }
      })

    },
    // getinfor(data) {
    //   this.editData = data
    // },
    resolveUserRoleVariant(role_id) {
      if (role_id === '管理员') return 'success'
      if (role_id === '用户') return 'warning'
      return 'success'
    },
    resolveUserRoleIcon(role_id) {
      if (role_id === '管理员') return 'UserIcon'
      if (role_id === '用户') return 'AwardIcon'
      return 'UserIcon'
    },
    resolveUserStatusVariant(status) {
      if (status === '0') return 'warning'
      if (status === '1') return 'success'
      return 'primary'
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.tabledata {
  border-radius: 3px;
}

.b-table-sticky-header {
  max-height: calc(100vh - 400px) !important;
}

.custom-select {
  padding: 0.438rem 0rem 0.438rem 0.6rem;
  width: 3.4rem;
}

.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}

.filter {
  margin-bottom: 2rem;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1.1rem;
  font-weight: 600;
  /* padding: 10px 0; */
}
.card{
  margin-bottom: 0 !important;
}
</style>
