<template>
    <section id="card-actions">
        <validation-observer ref="simpleRules">
        <b-row>
            <b-col cols="12">
                <b-card>
 
                            <!-- 头像名称 -->
                            <b-media class="mb-2">
                                <template #aside>
                                    <b-avatar  :src="userData.avatar" :text="avatarText(userData.name)" size="60px" rounded />
                                </template>
                                <!-- <h4 class="mb-1">
                                    {{ userData.fullName }}
                                </h4> -->
                                <div class="d-flex flex-wrap mt-2">
                                    <b-button variant="primary" @click="$refs.refInputEl.click()">
                                        <input ref="refInputEl" type="file" class="d-none" @change="upload_img">
                                        <span class="d-none d-sm-inline">上传</span>
                                        <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                                    </b-button>
                                    <b-button variant="outline-secondary" class="ml-1" small @click="chane_tx">
                                        <span class="d-none d-sm-inline">移除</span>
                                        <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
                                    </b-button>
                                </div>
                            </b-media>
                            <!-- 个人信息 -->
                            <b-form>
                                <b-row>

                                    <!-- Field: Username -->
                                    <b-col cols="12" md="4">
                                        <b-form-group label="姓名" label-for="username">
                                            <validation-provider #default="{ errors }"  name="name" rules="required">
                                            <b-form-input id="username" placeholder="请输入姓名" v-model="userData.name" :state="errors.length > 0 ? false:null"/>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Field: Full Name -->
                                    <b-col cols="12" md="4">
                                        <b-form-group label="手机" label-for="phone">
                                            <validation-provider #default="{ errors }"  name="phone" rules="required">
                                            <b-form-input id="full-name" placeholder="请输入手机" v-model="userData.phone" :state="errors.length > 0 ? false:null"/>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Field: Email -->
                                    <b-col cols="12" md="4">
                                        <b-form-group label="邮箱" label-for="email">
                                            <validation-provider #default="{ errors }" name="Email" rules="required|email">
                                            <b-form-input id="email" v-model="userData.email" type="email" :state="errors.length > 0 ? false:null" placeholder="请输入邮箱"/>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    </b-row>
                                    <b-row>
                                    
                                    <!-- 账号 -->
                                    <b-col cols="12" md="4">
                                        <b-form-group label="账号" label-for="account">
                                            <validation-provider name="account" rules="required"  #default="{ errors }">
                                            <b-form-input id="account" v-model="userData.account" :state="errors.length > 0 ? false:null" placeholder="请输入账号"/>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="4">
                                        <b-form-group label="密码" label-for="password">
                                            <validation-provider name="password" rules="required"  #default="{ errors }">
                                            <b-form-input id="password" v-model="userData.password" type="password" :state="errors.length > 0 ? false:null"  placeholder="请输入密码"/>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="4">
                                        <b-form-group label="角色" label-for="user-role">
                                            <validation-provider name="userrole" rules="required"  #default="{ errors }">
                                            <v-select v-model="role_id" :options="roleOptions" 
                                                :reduce="val => val.value"
                                                :state="errors.length > 0 ? false:null"  placeholder="请选择角色" />
                                            </validation-provider>

                                        </b-form-group>
                                    </b-col>

                                </b-row>
                            </b-form>
                            <b-card no-body class="border mt-1">
                                <b-card-header class="p-1">
                                    <b-card-title class="font-medium-2">
                                        <feather-icon icon="LockIcon" size="18" />
                                        <span class="align-middle ml-50">操作权限</span>
                                    </b-card-title>
                                </b-card-header>
                                <vue-perfect-scrollbar  ref="refChatLogPS" :settings="perfectScrollbarSettings" class="user_roles_mod scroll-area">

                                    <b-table responsive :items="roles_all"  :fields="fields" class="mb-0">
                                        <template #cell(label)="data" ><feather-icon :icon="data.item.icon" size="16"/> {{ data.item.label }}</template>
                                        <template #cell(children)="data">
                                            <b-list-group>
                                                <b-list-group-item v-for="item in data.item.children" :key="item.index">
                                                    <span class="mr-1"><feather-icon :icon="item.icon"/>&nbsp;{{item.label}}</span>
                                                    <span v-for="lanmu in item.acts" :key="lanmu.index">
                                                        <b-form-checkbox  v-model="selected" :value="item.id+'_'+lanmu" />
                                                        {{lanmu}}
                                                    </span>
                                                </b-list-group-item> 
                                            </b-list-group>
                                        </template>
                                        <!-- 
                                        <template #cell()="data">
                                            <b-form-checkbox disabled :checked="data.value" />
                                        </template> -->
                                    </b-table>

                                    </vue-perfect-scrollbar>
                            </b-card>

                            <!-- Action Buttons -->
                            <b-button variant="primary" class="mt-1 mb-sm-0 mr-0 mr-sm-1" @click="save()">
                                保存
                            </b-button>
             

               
                </b-card>
            </b-col>
        </b-row>
        </validation-observer>
    </section>
</template>

<script>
import {
    BButton, BMedia, BCardText,BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,BListGroup, BListGroupItem
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BCardActions,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    VuePerfectScrollbar,
    BListGroup, BListGroupItem,
    BCardText

},
data() {
    return {
        count:0,
        required,
        email,
        avatarText,
        roles_all:[],
        roleOptions: [],
        statusOptions: [
            { label: '冻结', value: '0' },
            { label: '正常', value: '1' },
        ],
        fields: [
            {key: 'label', label: '主模块',},
            {key: 'children', label: '子模块',}, 
        ],
        permissionsData: [],
        perfectScrollbarSettings: {
                maxScrollbarLength: 350,
                wheelPropagation: false,
            },
        role_id:"1",
        selected:[],
        userData: {
            act: '',
            id: '',
            avatar: '',
            name: '',
            email: '',
            phone: '',
            account: '',
            password: '',
            status: 1,
            role_id: "1",
            extras_role: ''
        }
    }
},
props: ['editData'],
watch: {
    editData(newVal, oldVal) {
        if (newVal) {
            this.userData = newVal
            this.role_id = this.userData.role_id
            this.get_roles_all()
            this.count = 0
        }
    },
    role_id: function (oldV, newV) {
      if(oldV!=newV){
        this.count++
        //编辑时候第一次加载是有数据的，第二次选择角色后就要清除数据
        if(this.count>1){
            this.userData.extras_role=""
        }
        this.get_roles_all()
        this.userData.role_id=this.role_id
      }
    },
},
created() {
   this.get_roles()
   this.get_roles_all()
},
methods: {
    get_roles_all() {
      let dataObj = {
        act: "get_role", 
        rid: this.role_id
      }
      this.$http.post('svr/admin_users.php', dataObj)
        .then(res => {
          this.roles_all = res.data
          this.selected=[]
          for(let i=0;i<res.data.length;i++){
            let children = res.data[i].children
            for(let j=0;j<children.length;j++){
                let children_id = children[j].id
                let children_acts = children[j].acts
                for(let k=0;k<children_acts.length;k++){
                    let children_act = children_acts[k]
                    this.selected.push(children_id+"_"+children_act);
                }
            }
          }
          if(this.userData.extras_role){this.selected =  this.userData.extras_role}
        })
    },
    clearfrom(){
        this.userData = {
            act: '',
            id: '',
            avatar: '',
            name: '',
            email: '',
            phone: '',
            account: '',
            password: '',
            status: 1,
            role_id: "1",
            extras_role: ''
        }
        //this.userData.avatar = "";
    },
    chane_tx(){
        this.userData.avatar = "";
    },
    get_roles(){
      let dataObj = {
        act: "get_role", 
      }
      this.$http.post('svr/admin_role.php', dataObj)
        .then(res => {
          this.roleOptions = res.data 
      })
    },
    upload_img(event) {
        let that = this 
        var file = event.target.files[0]        //获取文件         
        const formData = new FormData();
        formData.append("fle", file);
        axios({
            method: "post",
            headers: {
            "Content-Type": "multipart/form-data",
            },
            url: "http://yunhu.njqianyan.cn/wlwpt/svr/uld.php?act=pic",
            data: formData,
        })
        .then(function (response) {
            console.log(response.data.img,"返回头像");
            that.userData.avatar = response.data.img;
        })
        .catch(function (error) {
            console.log(error);
        }); 
    },

    showToast(variant,text) {
        this.$toast({
            component: ToastificationContent,
            props: {
            title: text,
            icon: 'BellIcon',
            variant,
            },
            },{ position:'top-center' })
    },
    save() {
        this.$refs.simpleRules.validate().then(success => {
            if (success) {
                if (!this.userData.id) {
                    this.userData.act = 'add'
                } else {
                    this.userData.act = 'mod' 
                }
                this.editData.id = this.userData.id
                this.userData.extras_role = JSON.stringify(this.selected)
                this.$http.post('svr/admin_users.php', this.userData).then(res => {
                    if (res.code == 20000) {
                        this.showToast('success','保存成功!')
                        this.$emit('get_data')
                    }else{
                        this.showToast('danger',res.msg)
                    }
                })
            }
        })
        
    },

},

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.tabledata {
    border-radius: 3px;
}
.custom-select {
    padding: 0.438rem 0rem 0.438rem 0.6rem;
    width: 3.4rem;
}

.b-table-selectable {
    .feather {
        font-size: 1.3rem;
    }
}
.filter {
    margin-bottom: 2rem;
}
.b-table-sticky-header {
  max-height: calc(100vh - 520px);
}
.card-actions {
    padding: 2rem;
}
.user_roles_mod{
  height: calc(100vh - 460px) !important; 
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.custom-control{
    display: inline-block !important;
}
.card{
  margin-bottom: 0 !important;
}
</style>