<template>
<section>
   <b-row>
        <b-col lg="4" class="d-flex flex-column mt-1">
            <b-card class="mt-1">
            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-start">
                <b-avatar :src="userData.avatar" :text="avatarText(userData.name)" size="104px" rounded />
                <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                        <h4 class="mb-0">{{ userData.name }}</h4>
                        <span class="card-text">{{ userData.email }}</span>
                    </div>
                    <div class="d-flex flex-wrap">
                        <b-button @click="close_user(1)" variant="primary">正常</b-button>
                        <b-button @click="close_user(0)" variant="outline-danger" class="ml-1">冻结</b-button>
                    </div>
                </div>
            </div>

            <!-- User Stats -->
            <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center mr-2">
                    <b-avatar variant="light-primary" rounded><feather-icon icon="CropIcon" size="18" /></b-avatar>
                    <div class="ml-1"><h5 class="mb-0">{{ userData.logs_log }}</h5> <small>系统登入</small></div>
                </div>

                <div class="d-flex align-items-center">
                    <b-avatar variant="light-success" rounded><feather-icon icon="TrendingUpIcon" size="18" /></b-avatar>
                    <div class="ml-1"><h5 class="mb-0">{{ userData.logs_api }}</h5><small>接口操作</small></div>
                </div>
            </div>
            </b-card>

        </b-col>

        <!-- Right Col: Table -->
        <b-col lg="4" class="mt-1">
            <b-card class=" mt-1">
            <table class="mt-2 mt-xl-0 w-100">
                <tr>
                    <th class="pb-50">
                        <feather-icon icon="UserIcon" class="mr-75" />
                        <span class="font-weight-bold">姓名</span>
                    </th>
                    <td class="pb-50">
                        {{ userData.name }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <feather-icon icon="CheckIcon" class="mr-75" />
                        <span class="font-weight-bold">状态</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        <span v-if="userData.status == 1">正常</span>
                        <span v-else>冻结</span>
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <feather-icon icon="StarIcon" class="mr-75" />
                        <span class="font-weight-bold">角色</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        {{ userData.role_name }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <feather-icon icon="AtSignIcon" class="mr-75" />
                        <span class="font-weight-bold">账号</span>
                    </th>
                    <td class="pb-50">
                        {{ userData.account }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                        <feather-icon icon="PhoneIcon" class="mr-75" />
                        <span class="font-weight-bold">电话</span>
                    </th>
                    <td class="pb-50">
                        {{ userData.phone }}
                    </td>
                </tr>
                <tr>
                    <th>
                        <feather-icon icon="MailIcon" class="mr-75" />
                        <span class="font-weight-bold">邮箱</span>
                    </th>
                    <td>
                        {{ userData.email }}
                    </td>
                </tr>
            </table>
            </b-card>
        </b-col>
        <b-col  lg="4">
            <b-card no-body class="border-primary mt-1">
                <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
                    <h5 class="mb-0">
                        上次登入
                    </h5>
                    <b-badge variant="light-primary">
                        PC
                    </b-badge>
                    <small class="text-muted w-100">{{userData.last_log}}</small>
                </b-card-header>

                <b-card-body>
                    <ul class="list-unstyled my-1">
                        <li>
                            <span class="align-middle"><feather-icon icon="AlertOctagonIcon" class="mr-25"/>管理员登入情况&nbsp;<b-badge variant="success">良好</b-badge></span>
                        </li>
                        <li class="my-25">
                            <span class="align-middle"><feather-icon icon="AlertOctagonIcon" class="mr-25"/>无异常操作</span>
                        </li>
                        <li>
                            <span class="align-middle"><feather-icon icon="AlertOctagonIcon" class="mr-25"/>如果存在多次密码错误或者遭遇暴力破解系统将自动锁定该用户</span>
                        </li>
                    </ul>
                    <b-button :to="{ name: 'system-logs', params: { id: 0 } }" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block>
                        查看日志
                    </b-button>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>

    <b-row class="mt-1">
        <b-col lg="4">
            <b-card title="操作记录" >
                <vue-perfect-scrollbar  ref="refChatLogPS" :settings="perfectScrollbarSettings" class="user_logs scroll-area">
                <app-timeline>
                    <!-- <app-timeline-item v-for="data in items" :key="data.id" :title="data.adm_id" :subtitle="data.data" :time="data.datetime" :variant="data.color">
                    </app-timeline-item> -->
                    <app-timeline-item :variant="data.color" v-for="data in userData.timeline" :key="data.id"  :icon="data.icons">
                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                            <h6><feather-icon icon="UsersIcon"/>&nbsp;操作员:{{data.adm_id}}&nbsp;&nbsp;<feather-icon icon="ToolIcon"/>&nbsp;类型:{{data.data.cz_title}}</h6>
                            <small class="text-muted">{{data.datetime}}</small>
                        </div>
                        <b-media class="mt-1">
                            <template #aside>
                                <img :src="data.data.img" class="product"/>
                            </template>
                            <h6 style="display:inline-block;width:20rem;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{data.data.title}}</h6>
                            <p class="mb-0">
                                {{data.data.sub_title}}
                            </p>
                        </b-media>
                    </app-timeline-item>
                
                </app-timeline>
                </vue-perfect-scrollbar>
            </b-card>
        </b-col>
        <b-col lg="8">
            <b-card  title="操作权限">
                <vue-perfect-scrollbar  ref="refChatLogPS" :settings="perfectScrollbarSettings" class="user_roles scroll-area">
                <b-table responsive small  :items="roles_all"  :fields="fields" class="mb-0">
                    <template #cell(label)="data" ><feather-icon :icon="data.item.icon" size="16"/> {{ data.item.label }}</template>
                    <template #cell(children)="data">
                        <b-list-group>
                            <b-list-group-item v-for="item in data.item.children" :key="item.index">
                                <span class="mr-1"><feather-icon :icon="item.icon"/>&nbsp;{{item.label}}</span>
                                <span v-for="lanmu in item.acts" :key="lanmu.index">
                                    <b-form-checkbox  v-model="selected" disabled :value="item.id+'_'+lanmu" />
                                    {{lanmu}}
                                </span>
                            </b-list-group-item> 
                        </b-list-group>
                    </template>
                    <!-- 
                    <template #cell()="data">
                        <b-form-checkbox disabled :checked="data.value" />
                    </template> -->
                </b-table>

                </vue-perfect-scrollbar>
            </b-card>
        </b-col>
    </b-row>
</section>
    
</template>
  
<script>
import {
    BCard,BCardText, BButton, BAvatar, BRow, BCol, BCardHeader, BCardBody, BBadge, BCardTitle, BCardSubTitle, BFormCheckbox, BTable, BImg, BMedia,BListGroup, BListGroupItem
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { avatarText } from '@core/utils/filter'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'


export default {
    directives: {
        Ripple,
    },
    components: {
        BCard, BButton, BRow, BCol, BAvatar, BCardHeader, BCardBody, BBadge,BCardText,
        BCardTitle, BCardSubTitle, BFormCheckbox, BTable, BCard,
        BImg,
        BMedia,
        AppTimeline,
        AppTimelineItem,
        ToastificationContent,
        VuePerfectScrollbar,
        BListGroup, BListGroupItem
    },

    data() {
        return {
            avatarText,
            role_id:1,
            roles_all:[],
            property: 'value',
            perfectScrollbarSettings: {
                maxScrollbarLength: 350,
                wheelPropagation: false,
            },
            timeline:[],
            fields: [
                {key: 'label', label: '主模块',},
                {key: 'children', label: '子模块',}, 
            ],
            permissionsData: [],
            userData: {}
        };
    },
    props: ['detailstData'],
    watch: {
        detailstData(newVal, oldVal) {
            if (newVal) {
                this.userData = newVal
                this.role_id = this.userData.role_id
                this.get_roles_all()
            }
        }
    },
    created() {
    },
    methods: {
        get_roles_all() {
        let dataObj = {
            act: "get_role", 
            rid: this.role_id
        }
        this.$http.post('svr/admin_users.php', dataObj)
            .then(res => {
            this.roles_all = res.data
            this.selected =  this.userData.extras_role
            })
        },
        showToast(variant,text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                title: text,
                icon: 'BellIcon',
                variant,
                },
                },{ position:'top-center' })
        },
        close_user(status) {
            let getdata = {
                act: 'status',
                id: this.detailstData.id,
                status: status
            }
            this.$http.post('svr/admin_users.php', getdata).then(res => {
                console.log(res, 'res');
                if (res.code == 20000) {
                    this.userData.status = status
                    this.showToast('success','保存成功!')
                    this.$emit('get_data')
                }
            })
        },
    }

}
</script>
  
<style>
.head .card-body {
    display: flex;
}
.product{
  height: 45px;
  border-radius: 5px;
}
.user_logs{
  height: calc(100vh - 420px) !important; 
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.user_roles{
  height: calc(100vh - 420px) !important; 
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.custom-control{
    display: inline-block !important;
}
</style>
  