var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"card-actions"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"src":_vm.userData.avatar,"text":_vm.avatarText(_vm.userData.name),"size":"60px","rounded":""}})]},proxy:true}])},[_c('div',{staticClass:"d-flex flex-wrap mt-2"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.upload_img}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("上传")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-secondary","small":""},on:{"click":_vm.chane_tx}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("移除")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1)],1)]),_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"姓名","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","placeholder":"请输入姓名","state":errors.length > 0 ? false:null},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"手机","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"full-name","placeholder":"请输入手机","state":errors.length > 0 ? false:null},model:{value:(_vm.userData.phone),callback:function ($$v) {_vm.$set(_vm.userData, "phone", $$v)},expression:"userData.phone"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"邮箱","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false:null,"placeholder":"请输入邮箱"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"账号","label-for":"account"}},[_c('validation-provider',{attrs:{"name":"account","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"account","state":errors.length > 0 ? false:null,"placeholder":"请输入账号"},model:{value:(_vm.userData.account),callback:function ($$v) {_vm.$set(_vm.userData, "account", $$v)},expression:"userData.account"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"密码","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"password","state":errors.length > 0 ? false:null,"placeholder":"请输入密码"},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"角色","label-for":"user-role"}},[_c('validation-provider',{attrs:{"name":"userrole","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.roleOptions,"reduce":function (val) { return val.value; },"state":errors.length > 0 ? false:null,"placeholder":"请选择角色"},model:{value:(_vm.role_id),callback:function ($$v) {_vm.role_id=$$v},expression:"role_id"}})]}}])})],1)],1)],1)],1),_c('b-card',{staticClass:"border mt-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-2"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("操作权限")])],1)],1),_c('vue-perfect-scrollbar',{ref:"refChatLogPS",staticClass:"user_roles_mod scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings}},[_c('b-table',{staticClass:"mb-0",attrs:{"responsive":"","items":_vm.roles_all,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(label)",fn:function(data){return [_c('feather-icon',{attrs:{"icon":data.item.icon,"size":"16"}}),_vm._v(" "+_vm._s(data.item.label))]}},{key:"cell(children)",fn:function(data){return [_c('b-list-group',_vm._l((data.item.children),function(item){return _c('b-list-group-item',{key:item.index},[_c('span',{staticClass:"mr-1"},[_c('feather-icon',{attrs:{"icon":item.icon}}),_vm._v(" "+_vm._s(item.label))],1),_vm._l((item.acts),function(lanmu){return _c('span',{key:lanmu.index},[_c('b-form-checkbox',{attrs:{"value":item.id+'_'+lanmu},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "+_vm._s(lanmu)+" ")],1)})],2)}),1)]}}])})],1)],1),_c('b-button',{staticClass:"mt-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" 保存 ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }